import React from "react"
import { useEffect } from "react"

// @material-ui/icons
import { Button, Table, Container } from "reactstrap"
// import Button from "components/CustomButtons/Button.js"
// import Hidden from "@material-ui/core/Hidden"
// import Table from "@material-ui/core/Table"
// import tbody from "@material-ui/core/tbody"
// import td from "@material-ui/core/td"
// import TableContainer from "@material-ui/core/TableContainer"
// import TableHead from "@material-ui/core/TableHead"
// import tr from "@material-ui/core/tr"

// core components
import useFirebase from "components/useFirebase"
import Moment from "react-moment"

function Groups({ ...props }) {
  const firebase = useFirebase()
  const [groups, setGroups] = React.useState([])

  useEffect(() => {
    if (!firebase) return
    firebase
      .database()
      .ref("POCtest/groups/metadata")
      .once("value")
      .then(snapshot => {
        setGroups(snapshot.val())
      })
  }, [firebase])

  return (
    <Table striped aria-label="simple table">
      <thead>
        <tr>
          <th align="center">出團日期</th>
          <th>費用(加幣)</th>
          <th>團名/代號</th>
          <th>導遊</th>
          <th>空位</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {[]
          .concat(groups)
          .sort((a, b) => a.groupStart - b.groupStart)
          .filter(group => {
            return props.groupType ? group.groupType === props.groupType : true
          })
          .filter(group => {
            return props.groupDays ? group.groupDays === props.groupDays : true
          })
          .filter(group => {
            return props.groupName
              ? group.groupName.includes(props.groupName)
              : true
          })
          .map(group => (
            <tr key={group.id + group.groupFee}>
              <td align="center" component="th" scope="row">
                <Moment format="M/D">{group.groupStart}</Moment> -{" "}
                <Moment format="M/D">{group.groupEnd}</Moment>
              </td>
              <td>${group.groupFee}</td>
              <td>{group.groupName}</td>
              <td>{group.guides}</td>
              <td>{group.groupOpen}</td>
              <td>
                <Button
                  // variant="contained"
                  style={{ fontSize: 16 }}
                  color={group.groupOpen === "已額滿" ? "link" : "primary"}
                >
                  {group.groupOpen === "已額滿" ? "候補" : "報名"}
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default React.memo(Groups)
